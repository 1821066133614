/*
 * @Author: your name
 * @Date: 2021-03-09 14:23:12
 * @LastEditTime: 2021-03-10 11:29:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\assets\js\mixin\load-more.js
 */
export default {
  data() {
    return {
      dataSource: [],
      loading: false,
      page: 1,
      pageSize:10,
      total: 0
    }
  },
  computed: {
    finished() {
      return this.dataSource.length >= this.total
    }
  },
  methods: {
    async onLoad() {
      this.page++
      this.loading = true
      try {
        await this.getList()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getList() {}
  }
}
